import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate if using
import logo from "../images/logo.svg";
import { logoutUser } from "../redux/userSlice";
import API from "../utils/api";
function AppHeader() {
  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const dispatch = useDispatch();
  const userName = localStorage.getItem("userName");
  const useUserRoles = () => {
    let roles = useSelector((state) => state.user.userRoles);
    console.log("roles", roles, typeof roles);
    if (typeof roles === "string") {
      return roles.split(",");
    } else if (Array.isArray(roles)) {
      return roles;
    }
  };
  const userRoles = useUserRoles();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = (event) => {
    event.stopPropagation(); // Prevent click event propagation
    setDropdownVisible((prev) => !prev);
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation(); // Prevents event propagation to the parent elements.
  };

  const handleOutsideClick = () => {
    setDropdownVisible(false);
  };

  // Logout handler
  const handleLogout = async () => {
    API.post("/logout/")
      .then((response) => {
        console.log("Logout Response: ", response);
        dispatch(logoutUser());
        window.location.href = "/login";
      })
      .catch((error) => {
        console.log("Logout Error: ", error);
        navigate("/login");
      });
  };

  React.useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownVisible]);
  return (
    <div className="bg-[#282c34] m-0 p-0 w-[100vw] flex flex-row items-center justify-between text-gray-500 text-sm relative">
      <div className="flex pointer-events-none pl-5">
        <img src={logo} alt="Logo" style={{ height: "5vw" }} />
      </div>
      <div className="flex flex-row items-center justify-center space-x-10 text-lg">
        <a href="/dashboard" className="mx-4 text-white no-underline">
          Dashboard
        </a>
        <a href="/update/simple" className="mx-4 text-white no-underline">
          Update
        </a>
        <div className="mx-4 text-white no-underline">Contact</div>
        <div className="mx-4 text-white no-underline">About</div>
      </div>
      <div className="pr-5 text-sm relative">
        <div
          className="text-white cursor-pointer"
          onClick={toggleDropdown}
          onBlur={() => setDropdownVisible(false)}
        >
          {userName}
        </div>
        {dropdownVisible && (
          <div
            className="absolute right-0 mt-2 bg-white text-black rounded-md shadow-lg p-2 w-[150px]"
            onClick={handleDropdownClick}
          >
            {userRoles && userRoles.includes("admin") && (
              <>
                <a
                  href="/team/agents"
                  className="block px-4 py-2 hover:bg-gray-200 no-underline"
                >
                  Manage Agents
                </a>
                <a
                  href="/team/groups"
                  className="block px-4 py-2 hover:bg-gray-200 no-underline"
                >
                  Manage Groups
                </a>
                <a
                  href="/files/"
                  className="block px-4 py-2 hover:bg-gray-200 no-underline"
                >
                  Manage Files
                </a>
                <a
                  href="/warehouse/accessories"
                  className="block px-4 py-2 hover:bg-gray-200 no-underline"
                >
                  Manage Warehouse Accessories
                </a>
                <a
                  href="/warehouse/rmas"
                  className="block px-4 py-2 hover:bg-gray-200 no-underline"
                >
                  Manage Warehouse RMAs
                </a>
              </>
            )}
            <a
              href="/profile"
              className="block px-4 py-2 hover:bg-gray-200 no-underline"
            >
              Profile
            </a>
            <button
              onClick={handleLogout}
              className="block w-full text-middle px-4 py-2 hover:bg-gray-200 no-underline"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default AppHeader;
