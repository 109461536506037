import React, { useEffect, useState } from "react";
import { useWarehouseRMA } from "./useWarehouseRMA";

/**
 * @typedef {object} EditRMAPopupProps
 * @property {string} rmaId - The ID of the RMA to edit
 * @property {Function} onClose - Callback function when the edit is complete
 * @property {Function} onCancel - Callback function when editing is cancelled
 * @property {boolean} isOpen - Whether the popup is open
 * @property {Function} setNotification - Function to set notification state
 *
 * @param {EditRMAPopupProps} props
 * @returns {JSX.Element}
 */
export function EditRMAPopup({
  rmaId,
  onClose,
  onCancel,
  isOpen,
  setNotification,
}) {
  const { getRMA, updateRMA, error: rmaError } = useWarehouseRMA();
  const [rma, setRMA] = useState(null);
  const [formData, setFormData] = useState({
    product_info: {
      defective_model: "",
      defective_sn: "",
      replacement_model: "",
      replacement_sn: "",
    },
    receiver_info: {
      company_name: "",
      contact_name: "",
      country: "",
      city: "",
      address: "",
      postcode: "",
      contact_email: "",
      contact_phone: "",
    },
    shipping_method: "",
    shipping_status: "",
    remark: "",
  });

  useEffect(() => {
    const loadRMA = async () => {
      try {
        const data = await getRMA(rmaId);
        if (data) {
          setRMA(data);
          setFormData({
            product_info: {
              defective_model: data.product_info?.defective_model || "",
              defective_sn: data.product_info?.defective_sn || "",
              replacement_model: data.product_info?.replacement_model || "",
              replacement_sn: data.product_info?.replacement_sn || "",
            },
            receiver_info: {
              company_name: data.receiver_info?.company_name || "",
              contact_name: data.receiver_info?.contact_name || "",
              country: data.receiver_info?.country || "",
              city: data.receiver_info?.city || "",
              address: data.receiver_info?.address || "",
              postcode: data.receiver_info?.postcode || "",
              contact_email: data.receiver_info?.contact_email || "",
              contact_phone: data.receiver_info?.contact_phone || "",
            },
            shipping_method: data.shipping_method || "",
            shipping_status: data.shipping_status || "",
            remark: data.remark || "",
          });
        }
      } catch (error) {
        setNotification({
          title: "Error",
          message: "Failed to load RMA details",
          color: "red",
          timeToDisappear: 3000,
        });
      }
    };

    if (isOpen && rmaId) {
      loadRMA();
    }
  }, [rmaId, isOpen, getRMA, setNotification]);

  useEffect(() => {
    if (rmaError) {
      setNotification({
        title: "Error",
        message: rmaError,
        color: "red",
        timeToDisappear: 3000,
      });
    }
  }, [rmaError, setNotification]);

  const handleInputChange = (e, section, field) => {
    const { value } = e.target;
    if (section) {
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateRMA(rmaId, formData);
      setNotification({
        title: "Success",
        message: "RMA updated successfully",
        color: "green",
        timeToDisappear: 3000,
      });
      onClose();
    } catch (error) {
      setNotification({
        title: "Error",
        message: "Failed to update RMA",
        color: "red",
        timeToDisappear: 3000,
      });
    }
  };

  if (!isOpen || !rma) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">Edit RMA: {rmaId}</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Product Info Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Product Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Defective Model
                </label>
                <input
                  type="text"
                  value={formData.product_info.defective_model}
                  onChange={(e) =>
                    handleInputChange(e, "product_info", "defective_model")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Defective Serial Number
                </label>
                <input
                  type="text"
                  value={formData.product_info.defective_sn}
                  onChange={(e) =>
                    handleInputChange(e, "product_info", "defective_sn")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Replacement Model
                </label>
                <input
                  type="text"
                  value={formData.product_info.replacement_model}
                  onChange={(e) =>
                    handleInputChange(e, "product_info", "replacement_model")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Replacement Serial Number
                </label>
                <input
                  type="text"
                  value={formData.product_info.replacement_sn}
                  onChange={(e) =>
                    handleInputChange(e, "product_info", "replacement_sn")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
          </div>

          {/* Receiver Info Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Receiver Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Company Name
                </label>
                <input
                  type="text"
                  value={formData.receiver_info.company_name}
                  onChange={(e) =>
                    handleInputChange(e, "receiver_info", "company_name")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Contact Name
                </label>
                <input
                  type="text"
                  value={formData.receiver_info.contact_name}
                  onChange={(e) =>
                    handleInputChange(e, "receiver_info", "contact_name")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Country
                </label>
                <input
                  type="text"
                  value={formData.receiver_info.country}
                  onChange={(e) =>
                    handleInputChange(e, "receiver_info", "country")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <input
                  type="text"
                  value={formData.receiver_info.city}
                  onChange={(e) => handleInputChange(e, "receiver_info", "city")}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <input
                  type="text"
                  value={formData.receiver_info.address}
                  onChange={(e) =>
                    handleInputChange(e, "receiver_info", "address")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Postcode
                </label>
                <input
                  type="text"
                  value={formData.receiver_info.postcode}
                  onChange={(e) =>
                    handleInputChange(e, "receiver_info", "postcode")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  value={formData.receiver_info.contact_email}
                  onChange={(e) =>
                    handleInputChange(e, "receiver_info", "contact_email")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Phone
                </label>
                <input
                  type="text"
                  value={formData.receiver_info.contact_phone}
                  onChange={(e) =>
                    handleInputChange(e, "receiver_info", "contact_phone")
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
          </div>

          {/* Shipping Info Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Shipping Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Shipping Method
                </label>
                <input
                  type="text"
                  value={formData.shipping_method}
                  onChange={(e) => handleInputChange(e, null, "shipping_method")}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Shipping Status
                </label>
                <input
                  type="text"
                  value={formData.shipping_status}
                  onChange={(e) => handleInputChange(e, null, "shipping_status")}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
          </div>

          {/* Remark Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Remark
            </label>
            <textarea
              value={formData.remark}
              onChange={(e) => handleInputChange(e, null, "remark")}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
