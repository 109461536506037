import React, { useEffect, useState } from "react";
import { EditAccessoryPopup } from "../../components/EditAccessoryPopup";
import Notification from "../../components/notification";
import { useWarehouseAccessories } from "../../components/useWarehouseAccessories";
import { useWarehouseAccessory } from "../../components/useWarehouseAccessory";

/**
 * @typedef {object} AccessoryProductInfo
 * @property {string} [model]
 * @property {string} [shipped_sn]
 * @property {string} [quantity]
 * @property {string} [description]
 *
 * @typedef {object} ReceiverInfo
 * @property {string} [company_name]
 * @property {string} [contact_name]
 * @property {string} [country]
 * @property {string} [city]
 * @property {string} [address]
 * @property {string} [postcode]
 * @property {string} [email]
 * @property {string} [phone]
 * @property {string} [street]
 * @property {string} [house_number]
 * @property {string} [house_number_ext]
 *
 * @typedef {import("../../components/useWarehouseAccessories").Accessory & {
 *   product_info?: AccessoryProductInfo,
 *   receiver_info?: ReceiverInfo
 * }} ExtendedAccessory
 *
 * @function WarehouseAccessoriesPage
 * @description Renders a page for managing warehouse accessories, including detailed info.
 * The last column is reserved for future action buttons per accessory.
 * @returns {JSX.Element}
 */
export default function WarehouseAccessoriesPage() {
  const [editingAccessoryId, setEditingAccessoryId] = useState(null);
  const { accessories, fetchAccessories, error, loading } =
    useWarehouseAccessories();
  const { createShippingLabel, downloadShippingLabel } =
    useWarehouseAccessory();

  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });

  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };

  // Conditionally render the EditAccessoryPopup component if we have an accessory ID to edit
  const handleCloseEdit = () => {
    setEditingAccessoryId(null);
    fetchAccessories();
  };

  /**
   * Create Label using useWarehouseAccessory hook
   * @param {string} accessoryId
   */
  const handleCreateLabel = async (accessoryId) => {
    try {
      await createShippingLabel(accessoryId);
      // Re-fetch accessories to update shipping_label_url
      fetchAccessories();
    } catch (error) {
      const msg = error.response?.data?.error || "Error creating label";
      setNotification({
        title: "Error",
        message: msg,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  };

  /**
   * Download Label using useWarehouseAccessory hook
   * @param {string} accessoryId
   */
  const handleDownloadLabel = async (accessoryId) => {
    try {
      await downloadShippingLabel(accessoryId);
    } catch (error) {
      const msg = error.response?.data?.error || "Error downloading label";
      setNotification({
        title: "Error",
        message: msg,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  };

  /**
   * Fetch the accessories from the server on mount.
   */
  useEffect(() => {
    fetchAccessories(setNotification);
  }, [fetchAccessories, setNotification]);

  useEffect(() => {
    if (error) {
      setNotification({
        title: "Error",
        message: error,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-items-center w-[100vw] mt-5">
      <h1 className="text-xl font-bold mb-4">Warehouse Accessories</h1>

      {loading && <p>Loading accessories...</p>}

      {/* Table of Accessories */}
      <div className="overflow-x-auto">
        <table className="min-w-[80vw] table-auto border-collapse border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Ticket ID
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Product Info
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Receiver Info
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Shipping Method
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Status
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Tracking Number
              </th>

              <th className="border border-gray-300 px-4 py-2 text-left">
                Remark
              </th>
              <th className="border border-gray-300 px-4 py-2 text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {accessories.map((acc) => {
              const accessory = /** @type {ExtendedAccessory} */ (acc);
              return (
                <tr key={accessory.accessory_id} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2">
                    <a
                      href={`${process.env.REACT_APP_ZENDESK_BASE_URL}/agent/tickets/${accessory.ticket_id}`}
                      target="_blank"
                      className="text-blue-600 hover:underline"
                      rel="noreferrer"
                    >
                      {accessory.ticket_id}
                    </a>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {accessory.product_info ? (
                      <div className="space-y-1">
                        <p>
                          <strong>Model:</strong> {accessory.product_info.model}
                        </p>
                        <p>
                          <strong>Qty:</strong>{" "}
                          {accessory.product_info.quantity}
                        </p>
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {accessory.receiver_info ? (
                      <div className="space-y-1">
                        <p>
                          <strong>Company:</strong>{" "}
                          {accessory.receiver_info.company_name}
                        </p>
                        <p>
                          <strong>Contact:</strong>{" "}
                          {accessory.receiver_info.contact_name}
                        </p>
                        <p>
                          <strong>Country:</strong>{" "}
                          {accessory.receiver_info.country}
                        </p>
                        <p>
                          <strong>City:</strong> {accessory.receiver_info.city}
                        </p>
                        <p>
                          <strong>Address:</strong>{" "}
                          {accessory.receiver_info.address}
                        </p>
                        <p>
                          <strong>Postcode:</strong>{" "}
                          {accessory.receiver_info.postcode}
                        </p>
                        <p>
                          <strong>Email:</strong>{" "}
                          {accessory.receiver_info.contact_email}
                        </p>
                        <p>
                          <strong>Phone:</strong>{" "}
                          {accessory.receiver_info.contact_phone}
                        </p>
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {accessory.shipping_method}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {accessory.shipping_status}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {accessory.shipping_label_url ? (
                      <a
                        href={accessory.shipping_label_url}
                        target="_blank"
                        without
                        rel="noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {accessory.tracking_number}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>

                  <td className="border border-gray-300 px-4 py-2">
                    {accessory.remark}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    <div className="flex flex-col items-center justify-center space-y-2">
                      {accessory.verified_address && (
                        <button
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-3 rounded"
                          onClick={() =>
                            handleCreateLabel(accessory.accessory_id)
                          }
                        >
                          Create Label
                        </button>
                      )}
                      {accessory.shipping_label_url && (
                        <button
                          className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-1 px-3 rounded"
                          onClick={() =>
                            handleDownloadLabel(accessory.accessory_id)
                          }
                        >
                          Download Label
                        </button>
                      )}
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded"
                        onClick={() =>
                          setEditingAccessoryId(accessory.accessory_id)
                        }
                      >
                        Edit
                      </button>
                      <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded">
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {editingAccessoryId && (
          <div className="mt-4 border p-4">
            <h2 className="text-lg font-semibold mb-2">Edit Accessory</h2>
            <EditAccessoryPopup
              accessoryId={editingAccessoryId}
              onClose={handleCloseEdit}
              onCancel={() => setEditingAccessoryId(null)}
              isOpen={Boolean(editingAccessoryId)}
              setNotification={setNotification}
            />
          </div>
        )}
      </div>
      {notification.message && (
        <Notification
          title={notification.title}
          message={notification.message}
          color={notification.color}
          timeToDisappear={notification.timeToDisappear}
          onDisappear={handleResetNotification}
        />
      )}
    </div>
  );
}
