import {
  faArrowDownWideShort,
  faArrowUpWideShort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-modal";
import GroupAgentsEditPopup from "./GroupAgentsEditPopup";

const GroupsList = ({ groups, changeGroup: onChangeGroup }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  // remember the original groups' order
  const originalGroups = React.useMemo(() => {
    return [...groups];
  }, [groups]);
  const sortedGroups = React.useMemo(() => {
    // If no sorting is active, return the original order
    if (!sortConfig.key || !sortConfig.direction) {
      return [...originalGroups];
    }

    let sortableGroups = [...originalGroups];
    sortableGroups.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableGroups;
  }, [originalGroups, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        direction = "descending";
      } else if (sortConfig.direction === "descending") {
        direction = null;
        setSortConfig({ key: null, direction: null });
        return;
      } else {
        direction = "ascending";
      }
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        return <FontAwesomeIcon icon={faArrowUpWideShort} />;
      } else if (sortConfig.direction === "descending") {
        return <FontAwesomeIcon icon={faArrowDownWideShort} />;
      }
    }
    return null;
  };

  const openModal = (group) => {
    setSelectedGroup(group);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedGroup(null);
  };

  return (
    <div>
      <div className="flex items-center justify-center">
        <ul className="list-none p-0">
          <li key="group-head" className="mt-4 mb-2">
            <div className="grid grid-cols-6 gap-2 items-center font-bold">
              <div
                className="cursor-pointer flex items-center justify-center hover:text-blue-500"
                onClick={() => requestSort("name")}
              >
                Name {getArrow("name")}
              </div>
              <div
                className="cursor-pointer flex items-center justify-center hover:text-blue-500"
                onClick={() => requestSort("description")}
              >
                Description {getArrow("description")}
              </div>
              <div
                className="cursor-pointer flex items-center justify-center hover:text-blue-500"
                onClick={() => requestSort("country")}
              >
                Country {getArrow("country")}
              </div>
              <div
                className="cursor-pointer flex items-center justify-center hover:text-blue-500"
                onClick={() => requestSort("weight_factor")}
              >
                Weight Factor {getArrow("weight_factor")}
              </div>
              <div
                className="cursor-pointer flex items-center justify-center hover:text-blue-500"
                onClick={() => requestSort("workload")}
              >
                Workload {getArrow("workload")}
              </div>
              <div className="flex items-center justify-center">Agents</div>
            </div>
          </li>
          {sortedGroups &&
            sortedGroups.map((group) => {
              return (
                <li key={group.id} className="mb-2">
                  <div className="grid grid-cols-6 gap-2 items-center ">
                    <div>{group.name}</div>
                    <div>
                      <input
                        type="text"
                        className="border-2 border-gray-500 rounded-md disabled:opacity-50"
                        value={group.description}
                        disabled={true}
                        onChange={(e) =>
                          onChangeGroup(group.id, "description", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        className="border-2 border-gray-500 rounded-md disabled:opacity-50"
                        value={group.country}
                        onChange={(e) =>
                          onChangeGroup(group.id, "country", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        step="0.01"
                        className="border-2 border-gray-500 rounded-md disabled:opacity-50"
                        value={group.weight_factor}
                        onChange={(e) =>
                          onChangeGroup(
                            group.id,
                            "weight_factor",
                            e.target.value,
                          )
                        }
                      />
                    </div>
                    <div>
                      <input
                        type="integer"
                        className="border-2 border-gray-500 rounded-md disabled:opacity-50"
                        value={group.workload}
                        disabled={true}
                      />
                    </div>

                    <div className="flex items-center justify-center hover:text-blue-500">
                      <button onClick={() => openModal(group)}>
                        Edit ({group.agents.length})
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <GroupAgentsEditPopup group={selectedGroup} onClose={closeModal} />
      </Modal>
    </div>
  );
};

export default GroupsList;
