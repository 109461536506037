import React, { useCallback, useEffect, useState } from "react";
import { useWarehouseAccessory } from "./useWarehouseAccessory";

/**
 * @module EditAccessoryPopup
 * @description A popup-like React component styled similarly to EditFilePopup, allowing editing of accessory information.
 * Specifically includes productInfo and receiverInfo fields, laid out in a 3-column grid.
 *
 * @param {boolean} props.isOpen - Controls whether the edit modal is visible.
 * @param {string} props.accessoryId - The unique ID of the accessory to edit.
 * @param {function} [props.onClose] - Optional callback triggered after a successful update or when the user cancels.
 *
 * @example
 * <EditAccessoryPopup
 *   isOpen={true}
 *   accessoryId="12345"
 *   onClose={() => console.log("Edit closed")}
 * />
 */
export function EditAccessoryPopup({
  isOpen,
  accessoryId,
  onClose,
  onCancel,
  setNotification,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);

  // Product Info fields
  const [model, setModel] = useState("");
  const [shippedSn, setShippedSn] = useState("");
  const [quantity, setQuantity] = useState("");
  const [productDescription, setProductDescription] = useState("");

  // Receiver Info fields
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // Custom hook for updating the accessory
  const { error, loading, updateAccessory, getAccessory } =
    useWarehouseAccessory();

  /**
   * Fetch existing accessory data so we can prefill the form.
   */
  const fetchAccessoryData = useCallback(async () => {
    if (!accessoryId) return;
    setFetchLoading(true);
    try {
      const data = await getAccessory(accessoryId);
      if (data) {
        const { product_info = {}, receiver_info = {} } = data;
        setModel(product_info.model || "");
        setShippedSn(product_info.shipped_sn || "");
        setQuantity(product_info.quantity || "");
        setProductDescription(product_info.description || "");

        setCompanyName(receiver_info.company_name || "");
        setContactName(receiver_info.contact_name || "");
        setCountry(receiver_info.country || "");
        setCity(receiver_info.city || "");
        setAddress(receiver_info.address || "");
        setPostcode(receiver_info.postcode || "");
        setEmail(receiver_info.contact_email || "");
        setPhone(receiver_info.contact_phone || "");

        setNotification({
          title: "Success",
          message: "Accessory fetched successfully",
          color: "green",
          timeToDisappear: 3000,
        });
      }
    } catch (err) {
      const msg =
        err.response?.data?.error || error || "Failed to fetch accessory";

      setNotification({
        title: "Failure",
        message: msg,
        color: "red",
        timeToDisappear: 5000,
      });
    } finally {
      setFetchLoading(false);
    }
  }, [accessoryId, getAccessory, error, setNotification]);

  useEffect(() => {
    if (isOpen) {
      fetchAccessoryData();
    }
  }, [isOpen, fetchAccessoryData]);

  useEffect(() => {
    if (error) {
      setNotification({
        title: "Error",
        message: error,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  }, [error, setNotification]);

  if (!isOpen) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateAccessory(accessoryId, {
        product_info: {
          model,
          shipped_sn: shippedSn,
          quantity,
          description: productDescription,
        },
        receiver_info: {
          company_name: companyName,
          contact_name: contactName,
          country,
          city,
          address,
          postcode,
          email,
          phone,
        },
      });

      setNotification({
        title: "Success",
        message: "Accessory updated successfully",
        color: "green",
        timeToDisappear: 3000,
      });
      if (onClose) onClose();
    } catch (err) {
      const msg =
        err.response?.data?.error || error || "Failed to update accessory";

      setNotification({
        title: "Failure",
        message: msg,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-[90vw] max-w-[500px] shadow-lg">
        <h2 className="text-xl font-bold mb-4">Edit Accessory</h2>

        {fetchLoading ? (
          <p>Loading accessory data...</p>
        ) : (
          <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">
            {/* Product Info Section */}
            <div className="col-span-1 flex flex-col">
              <label className="mb-1 font-semibold">Model:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={model}
                onChange={(e) => setModel(e.target.value)}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="mb-1 font-semibold">Shipped SN:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={shippedSn}
                onChange={(e) => setShippedSn(e.target.value)}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="mb-1 font-semibold">Quantity:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className="col-span-3 flex flex-col">
              <label className="mb-1 font-semibold">Product Description:</label>
              <textarea
                className="px-3 py-2 h-16 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm resize-none"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
              />
            </div>

            {/* Receiver Info Section */}
            <div className="col-span-1 flex flex-col">
              <label className="mb-1 font-semibold">Company Name:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="mb-1 font-semibold">Contact Name:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="mb-1 font-semibold">Country:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="mb-1 font-semibold">City:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="col-span-2 flex flex-col">
              <label className="mb-1 font-semibold">Address:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="mb-1 font-semibold">Postcode:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
            </div>
            <div className="col-span-2 flex flex-col">
              <label className="mb-1 font-semibold">Email:</label>
              <input
                type="email"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-span-2 flex flex-col">
              <label className="mb-1 font-semibold">Phone:</label>
              <input
                type="text"
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            {/* Form Action Buttons */}
            <div className="col-span-3 flex justify-end space-x-3 mt-4">
              <button
                type="submit"
                disabled={loading}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow"
              >
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded shadow"
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditAccessoryPopup;
