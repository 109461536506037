import React, { useEffect, useState } from "react";

function Pagination({ currentPage, totalPages, onPageChange, loading }) {
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    if (!loading) {
      const pages = [];

      const validPage = (page) => page >= 1 && page <= totalPages;

      const offsets = [-10, -5, -2, -1, 0, 1, 2, 5, 10];

      offsets.forEach((offset) => {
        const page = currentPage + offset;
        if (validPage(page)) {
          pages.push({ number: page, label: page.toString() });
        }
      });

      setPageNumbers(pages);
    }
  }, [currentPage, totalPages, loading]);

  if (totalPages <= 1) return null;

  return (
    <div className="flex justify-center items-center gap-2 mt-4 pb-10">
      {/* First button */}
      <button
        onClick={() => !loading && onPageChange(1)}
        disabled={loading || currentPage === 1}
        className={`px-3 py-1 rounded ${
          loading || currentPage === 1 ? "bg-gray-100 text-gray-400" : "bg-gray-200 hover:bg-gray-300"
        }`}
      >
        First
      </button>

      {/* Page numbers */}
      {pageNumbers.map((page, index) => (
        <button
          key={index}
          onClick={() => !loading && onPageChange(page.number)}
          className={`px-3 py-1 rounded ${
            page.number === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 hover:bg-gray-300"
          }`}
          disabled={loading || page.number === currentPage}
        >
          {page.label}
        </button>
      ))}

      {/* Last button */}
      <button
        onClick={() => !loading && onPageChange(totalPages)}
        disabled={loading || currentPage === totalPages}
        className={`px-3 py-1 rounded ${
          loading || currentPage === totalPages ? "bg-gray-100 text-gray-400" : "bg-gray-200 hover:bg-gray-300"
        }`}
      >
        Last
      </button>
    </div>
  );
}

export default Pagination;
