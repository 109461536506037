import React, { useEffect, useState } from "react";
import { EditRMAPopup } from "../../components/EditRMAPopup";
import Notification from "../../components/notification";
import Pagination from "../../components/Pagination";
import { useWarehouseRMA } from "../../components/useWarehouseRMA";
import { useWarehouseRMAs } from "../../components/useWarehouseRMAs";

/**
 * @typedef {object} ProductInfo
 * @property {string} [defective_model]
 * @property {string} [defective_sn]
 * @property {string} [replacement_model]
 * @property {string} [replacement_sn]
 *
 * @typedef {object} ReceiverInfo
 * @property {string} [company_name]
 * @property {string} [contact_name]
 * @property {string} [country]
 * @property {string} [city]
 * @property {string} [address]
 * @property {string} [postcode]
 * @property {string} [contact_email]
 * @property {string} [contact_phone]
 *
 * @typedef {object} ReviewInfo
 * @property {string} [applied_at]
 * @property {string} [ticket_id]
 * @property {string} [reviewer]
 * @property {string} [reviewed_at]
 *
 * @typedef {import("../../components/useWarehouseRMAs").RMA & {
 *   product_info?: ProductInfo,
 *   receiver_info?: ReceiverInfo,
 *   review_info?: ReviewInfo
 * }} ExtendedRMA
 *
 * @function WarehouseRMAsPage
 * @description Renders a page for managing warehouse RMAs, including detailed info.
 * @returns {JSX.Element}
 */
export default function WarehouseRMAsPage() {
  const [editingRMAId, setEditingRMAId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { rmas, fetchRMAs, error, loading, pagination } = useWarehouseRMAs();
  const { createShippingLabel, downloadShippingLabel, deleteRMA } = useWarehouseRMA();

  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });

  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };

  // Conditionally render the EditRMAPopup component if we have an RMA ID to edit
  const handleCloseEdit = () => {
    setEditingRMAId(null);
    fetchRMAs();
  };

  /**
   * Create Label using useWarehouseRMA hook
   * @param {string} rmaId
   */
  const handleCreateLabel = async (rmaId) => {
    try {
      await createShippingLabel(rmaId);
      // Re-fetch RMAs to update shipping_label_url
      fetchRMAs();
    } catch (error) {
      const msg = error.response?.data?.error || "Error creating label";
      setNotification({
        title: "Error",
        message: msg,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  };

  /**
   * Download Label using useWarehouseRMA hook
   * @param {string} rmaId
   */
  const handleDownloadLabel = async (rmaId) => {
    try {
      await downloadShippingLabel(rmaId);
    } catch (error) {
      const msg = error.response?.data?.error || "Error downloading label";
      setNotification({
        title: "Error",
        message: msg,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  };

  /**
   * Delete RMA using useWarehouseRMA hook
   * @param {string} rmaId
   */
  const handleDeleteRMA = async (rmaId) => {
    if (window.confirm("Are you sure you want to delete this RMA?")) {
      try {
        await deleteRMA(rmaId);
        setNotification({
          title: "Success",
          message: "RMA deleted successfully",
          color: "green",
          timeToDisappear: 3000,
        });
        fetchRMAs();
      } catch (error) {
        const msg = error.response?.data?.error || "Error deleting RMA";
        setNotification({
          title: "Error",
          message: msg,
          color: "red",
          timeToDisappear: 5000,
        });
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchRMAs(setNotification, pageNumber);
  };

  useEffect(() => {
    fetchRMAs(setNotification, currentPage);
  }, [fetchRMAs, currentPage]);

  useEffect(() => {
    if (error) {
      setNotification({
        title: "Error",
        message: error,
        color: "red",
        timeToDisappear: 5000,
      });
    }
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-items-center w-[100vw] mt-5">
      <h1 className="text-xl font-bold mb-4">Warehouse RMAs</h1>

      {loading && <p>Loading RMAs...</p>}

      {/* Table of RMAs */}
      <div className="overflow-x-auto">
        <table className="max-w-[80vw] table-auto border-collapse border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 px-4 py-2 text-left">
                RMA ID
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">Review Info</th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Product Info
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Receiver Info
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">RMA status</th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Shipping Method
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Shipping Status
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Tracking Number
              </th>
              <th className="border border-gray-300 px-4 py-2 text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {rmas.map((rma, index) => {
              const extendedRMA = /** @type {ExtendedRMA} */ (rma);
              return (
                <tr
                  key={extendedRMA.rma_id}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                >
                  <td className="border border-gray-300 px-4 py-2">
                    {extendedRMA.rma_id}
                  </td>

                  <td className="border border-gray-300 px-4 py-2">
                    {extendedRMA.review_info ? (
                      <div className="space-y-1">
                        <p>
                          <strong>Applied At:</strong>{" "}
                          {extendedRMA.review_info.applied_at
                            ? extendedRMA.review_info.applied_at.toLocaleString()
                            : "N/A"}
                        </p>
                        <p>
                          <strong>Ticket ID:</strong>{" "}
                          {extendedRMA.review_info.ticket_id ? (
                            <a
                              href={`${process.env.REACT_APP_ZENDESK_BASE_URL}/agent/tickets/${extendedRMA.review_info.ticket_id}`}
                              target="_blank"
                              className="text-blue-600 hover:underline"
                              rel="noreferrer"
                            >
                              {extendedRMA.review_info.ticket_id}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </p>
                        <p>
                          <strong>Reviewer:</strong>{" "}
                          {extendedRMA.review_info.reviewer ? extendedRMA.review_info.reviewer : "N/A"}
                        </p>
                        <p>
                          <strong>Reviewed At:</strong>{" "}
                          {extendedRMA.review_info.reviewed_at
                            ? extendedRMA.review_info.reviewed_at.toLocaleString()
                            : "N/A"}
                        </p>
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {extendedRMA.product_info ? (
                      <div className="space-y-1">
                        <p>
                          <strong>Defective Model:</strong>{" "}
                          {extendedRMA.product_info.defective_model}
                        </p>
                        <p>
                          <strong>Defective SN:</strong>{" "}
                          {extendedRMA.product_info.defective_sn}
                        </p>
                        <p>
                          <strong>Replacement Model:</strong>{" "}
                          {extendedRMA.product_info.replacement_model}
                        </p>
                        <p>
                          <strong>Replacement SN:</strong>{" "}
                          {extendedRMA.product_info.replacement_sn}
                        </p>
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {extendedRMA.receiver_info ? (
                      <div className="space-y-1">
                        <p>
                          <strong>Company:</strong>{" "}
                          {extendedRMA.receiver_info.company_name}
                        </p>
                        <p>
                          <strong>Contact:</strong>{" "}
                          {extendedRMA.receiver_info.contact_name}
                        </p>
                        <p>
                          <strong>Country:</strong>{" "}
                          {extendedRMA.receiver_info.country}
                        </p>
                        <p>
                          <strong>City:</strong>{" "}
                          {extendedRMA.receiver_info.city}
                        </p>
                        <p>
                          <strong>Address:</strong>{" "}
                          {extendedRMA.receiver_info.address}
                        </p>
                        <p>
                          <strong>Postcode:</strong>{" "}
                          {extendedRMA.receiver_info.postcode}
                        </p>
                        <p>
                          <strong>Email:</strong>{" "}
                          {extendedRMA.receiver_info.contact_email}
                        </p>
                        <p>
                          <strong>Phone:</strong>{" "}
                          {extendedRMA.receiver_info.contact_phone}
                        </p>
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {extendedRMA.stage}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {extendedRMA.shipping_method}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {extendedRMA.shipping_status}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {extendedRMA.shipping_label_url ? (
                      <a
                        href={extendedRMA.shipping_label_url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {extendedRMA.tracking_number}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>

                  <td className="border border-gray-300 px-4 py-2 text-center">
                    <div className="flex flex-col items-center justify-center space-y-2">
                      {extendedRMA.verified_address && (
                        <button
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-3 rounded"
                          onClick={() => handleCreateLabel(extendedRMA.rma_id)}
                        >
                          Create Label
                        </button>
                      )}
                      {extendedRMA.shipping_label_url && (
                        <button
                          className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-1 px-3 rounded"
                          onClick={() =>
                            handleDownloadLabel(extendedRMA.rma_id)
                          }
                        >
                          Download Label
                        </button>
                      )}
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded"
                        onClick={() => setEditingRMAId(extendedRMA.rma_id)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded"
                        onClick={() => handleDeleteRMA(extendedRMA.rma_id)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {editingRMAId && (
          <EditRMAPopup
            rmaId={editingRMAId}
            onClose={handleCloseEdit}
            onCancel={() => setEditingRMAId(null)}
            isOpen={Boolean(editingRMAId)}
            setNotification={setNotification}
          />
        )}
      </div>

      {/* Add Pagination component */}
      {rmas.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(pagination.count / 10)}
          onPageChange={handlePageChange}
          loading={loading}
        />
      )}

      {notification.message && (
        <Notification
          title={notification.title}
          message={notification.message}
          color={notification.color}
          timeToDisappear={notification.timeToDisappear}
          onDisappear={handleResetNotification}
        />
      )}
    </div>
  );
}
