/**
 * @module useWarehouseAccessories
 * @description Custom React hook for retrieving and creating warehouse accessories using Axios-based API calls.
 *
 * @typedef {object} Accessory
 * @property {string} accessory_id - Unique identifier for the accessory
 * @property {string} name - The name of the accessory
 * @property {string} description - Detailed description
 * @property {string} [shipping_label_url] - Label URL if available
 * @property {string} [shipping_status] - Shipping status of the accessory
 *
 * @typedef {object} CreateAccessoryPayload
 * @property {string} name - The name of the accessory
 * @property {string} description - Detailed description
 *
 * @function useWarehouseAccessories
 * @description Hook that provides methods to fetch and create accessories from the warehouse system via the custom Axios API instance.
 * @returns {{
 *   accessories: Accessory[],
 *   error: string | null,
 *   loading: boolean,
 *   fetchAccessories: () => Promise<void>,
 *   createAccessory: (newAccessory: CreateAccessoryPayload) => Promise<void>
 * }}
 * Returns an object containing:
 * - accessories: The array of current accessories
 * - error: Any error message from the last operation
 * - loading: Indicates operation in progress
 * - fetchAccessories: Function to retrieve all accessories
 * - createAccessory: Function to create a new accessory
 */

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../utils/api";

export function useWarehouseAccessories() {
  const navigate = useNavigate();
  const [accessories, setAccessories] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * @async
   * @function fetchAccessories
   * @description Retrieves the list of accessories from the API endpoint, updating state accordingly.
   * @returns {Promise<void>}
   */
  const fetchAccessories = useCallback(
    async (setNotification) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.get("/warehouse/accessories/");
        setAccessories(response.data.results || []);
        setNotification &&
          setNotification({
            title: "Success",
            message: "Accessories fetched successfully",
            color: "green",
            timeToDisappear: 3000,
          });
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/accessories");
        } else {
          const msg =
            error.response?.data?.error || "Failed to fetch accessories";
          setNotification &&
            setNotification({
              title: "Error",
              message: msg,
              color: "red",
              timeToDisappear: 3000,
            });
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate, error],
  );

  return {
    accessories,
    error,
    loading,
    fetchAccessories,
  };
}
