import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../utils/api";

/**
 * @module useWarehouseRMA
 * @description Custom React hook for fetching, updating, and deleting a warehouse RMA, and creating/downloading shipping labels.
 *
 * References:
 *  - RMADetailView (GET, PUT, DELETE) at /warehouse/rmas/<str:rma_id>/
 *  - RMAShippingLabelView at /warehouse/rmas/<str:rma_id>/shipping_label/
 *
 * @typedef {object} UpdateRMAPayload
 * @property {object} [product_info] - Updated product information
 * @property {object} [receiver_info] - Updated receiver information
 * @property {string} [shipping_status] - Updated shipping status
 *
 * @typedef {object} ShippingLabelResponse
 * @property {string} rma_id - ID of the RMA
 * @property {boolean} shipping_label_created - Indicates if a shipping label was created
 * @property {string} shipping_status - Shipping status of the RMA
 * @property {string} shipping_label_url - URL of the shipping label
 *
 * @function useWarehouseRMA
 * @description Hook that provides methods to fetch, update, delete RMAs, and create/download shipping labels via Axios-based API calls.
 * @returns {{
 *   error: string | null,
 *   loading: boolean,
 *   updatedRMA: object | null,
 *   deletedRMAId: string | null,
 *   shippingLabelData: ShippingLabelResponse | null,
 *   updateRMA: (rmaId: string, updatedFields: UpdateRMAPayload) => Promise<void>,
 *   deleteRMA: (rmaId: string) => Promise<void>,
 *   createShippingLabel: (rmaId: string) => Promise<void>,
 *   downloadShippingLabel: (rmaId: string) => Promise<void>,
 *   getRMA: (rmaId: string) => Promise<any | null>
 * }}
 */
export function useWarehouseRMA() {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatedRMA, setUpdatedRMA] = useState(null);
  const [deletedRMAId, setDeletedRMAId] = useState(null);
  const [shippingLabelData, setShippingLabelData] = useState(null);

  /**
   * @async
   * @function getRMA
   * @description Fetches a single RMA by sending a GET request.
   * @param {string} rmaId - The unique ID of the RMA to fetch
   * @returns {Promise<any|null>} The RMA data or null on failure
   */
  const getRMA = useCallback(
    async (rmaId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.get(`/warehouse/rmas/${rmaId}/`);
        return response.data;
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/rmas");
        } else {
          throw err;
        }
        return null;
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  /**
   * @async
   * @function updateRMA
   * @description Updates an RMA by sending a PUT request.
   * @param {string} rmaId - The unique ID of the RMA to update
   * @param {UpdateRMAPayload} updatedFields - Fields to update in the RMA
   * @returns {Promise<void>}
   */
  const updateRMA = useCallback(
    async (rmaId, updatedFields) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.put(
          `/warehouse/rmas/${rmaId}/`,
          updatedFields,
        );
        setUpdatedRMA(response.data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/rmas");
        } else {
          setError(
            err?.response?.data?.error ||
              err.message ||
              "Failed to update the RMA",
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  /**
   * @async
   * @function deleteRMA
   * @description Deletes an RMA by sending a DELETE request.
   * @param {string} rmaId - The unique ID of the RMA to delete
   * @returns {Promise<void>}
   */
  const deleteRMA = useCallback(
    async (rmaId) => {
      setLoading(true);
      setError(null);
      try {
        await API.delete(`/warehouse/rmas/${rmaId}/`);
        setDeletedRMAId(rmaId);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/rmas");
        } else {
          setError(
            err?.response?.data?.error ||
              err.message ||
              "Failed to delete the RMA",
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  /**
   * @async
   * @function createShippingLabel
   * @description Creates or updates a shipping label for an RMA by sending a POST request.
   * @param {string} rmaId - The unique ID of the RMA to create a shipping label for
   * @returns {Promise<void>}
   */
  const createShippingLabel = useCallback(
    async (rmaId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.post(
          `/warehouse/rmas/${rmaId}/shipping_label/`,
        );
        setShippingLabelData(response.data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/rmas");
        } else {
          setError(
            err?.response?.data?.error ||
              err.message ||
              "Failed to create shipping label",
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  /**
   * @async
   * @function downloadShippingLabel
   * @description Retrieves the PDF from a GET request (FileResponse with content_type="application/pdf") and opens it inline so the user can view it in a new tab.
   * @param {string} rmaId - The unique ID of the RMA to download or view the shipping label for
   * @returns {Promise<void>}
   */
  const downloadShippingLabel = useCallback(
    async (rmaId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.get(
          `/warehouse/rmas/${rmaId}/shipping_label/`,
          { responseType: "blob" },
        );

        const contentDisposition =
          response.headers["content-disposition"] || "";
        let filename = "shipping_label.pdf";
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        if (match && match[1]) {
          filename = match[1];
        }

        const blobUrl = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobUrl);
        }, 60000);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/rmas");
        } else {
          setError(
            err?.response?.data?.error ||
              err.message ||
              "Failed to download (view) shipping label",
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  return {
    error,
    loading,
    updatedRMA,
    deletedRMAId,
    shippingLabelData,
    updateRMA,
    deleteRMA,
    createShippingLabel,
    downloadShippingLabel,
    getRMA,
  };
}
