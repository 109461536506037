// utils/loadPages.js
export default function loadPages() {
  const context = require.context("../pages", true, /\.js$/);
  return context
    .keys()
    .map((key) => {
      const pageModule = context(key);
      const defaultExport = pageModule.default;
      if (!defaultExport) {
        // skip files that do not have a default export
        return null;
      }
      return {
        path: `/${key.replace(/\.\/|\.js/g, "")}`, // Example: /login
        element: defaultExport,
        exact: true, // You can adjust this based on your routing needs
      };
    })
    .filter(Boolean);
}
