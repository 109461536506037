/**
 * @module useWarehouseRMAs
 * @description Custom React hook for retrieving and creating warehouse RMAs using Axios-based API calls.
 *
 * @typedef {object} RMA
 * @property {string} rma_id - Unique identifier for the RMA
 * @property {object} product_info - Product information
 * @property {object} receiver_info - Receiver information
 * @property {string} [shipping_label_url] - Label URL if available
 * @property {string} [shipping_status] - Shipping status of the RMA
 *
 * @function useWarehouseRMAs
 * @description Hook that provides methods to fetch RMAs from the warehouse system via the custom Axios API instance.
 * @returns {{
 *   rmas: RMA[],
 *   error: string | null,
 *   loading: boolean,
 *   fetchRMAs: () => Promise<void>
 * }}
 */

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../utils/api";

export function useWarehouseRMAs() {
  const navigate = useNavigate();
  const [rmas, setRMAs] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  /**
   * @async
   * @function fetchRMAs
   * @description Retrieves the list of RMAs from the API endpoint, updating state accordingly.
   * @returns {Promise<void>}
   */
  const fetchRMAs = useCallback(
    async (setNotification, page = 1) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.get(`/warehouse/rmas/?page=${page}`);
        setRMAs(response.data.results || []);
        setPagination({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
        setNotification &&
          setNotification({
            title: "Success",
            message: "RMAs fetched successfully",
            color: "green",
            timeToDisappear: 3000,
          });
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/rmas");
        } else {
          const msg = err.response?.data?.error || "Failed to fetch RMAs";
          setNotification &&
            setNotification({
              title: "Error",
              message: msg,
              color: "red",
              timeToDisappear: 3000,
            });
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  return {
    rmas,
    error,
    loading,
    fetchRMAs,
    pagination,
  };
}
