import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import API from "../utils/api";

function EditFilePopup({ isOpen, onClose, file, onSave }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [localTags, setLocalTags] = useState([]); // Array of tags
  const [tagInput, setTagInput] = useState("");
  const [filteredTags, setFilteredTags] = useState([]); // Filtered existing tags
  const [existingTags, setExistingTags] = useState([]); // All possible tags from backend
  const [aiUsable, setAiUsable] = useState(false);
  const [isEmbedded, setIsEmbedded] = useState(false); // Track whether file is embedded

  // Fetch existing tags from backend
  const fetchTags = useCallback(
    debounce(() => {
      API.get("/files/tags")
        .then((response) => {
          setExistingTags(response.data.results || []);
        })
        .catch(() => {
          // For simplicity, ignoring errors here
        });
    }, 2000),
    [],
  );

  // Initialize data when file changes or popup opens
  useEffect(() => {
    if (isOpen) {
      fetchTags();
    }
  }, [isOpen, fetchTags]);

  useEffect(() => {
    if (file) {
      setName(file.name || "");
      setDescription(file.description || "");
      setLocalTags(Array.isArray(file.tags) ? file.tags : []);
      setAiUsable(file.aiUsable || false);
      setIsEmbedded(file.isEmbedded || false);
      setTagInput("");
      setFilteredTags([]);
    }
  }, [file]);

  // If popup is closed, return nothing
  if (!isOpen) {
    return null;
  }

  // Filter tags from existingTags that match user input and are not already in localTags
  const handleFilterTags = (query) => {
    if (query.trim() === "") {
      setFilteredTags([]);
      return;
    }
    const filtered = existingTags.filter(
      (tag) =>
        tag.toLowerCase().includes(query.toLowerCase()) &&
        !localTags.includes(tag),
    );
    setFilteredTags(filtered);
  };

  // Select a tag from the dropdown
  const handleSelectTag = (tag) => {
    setLocalTags((prev) => [...prev, tag]);
    setTagInput("");
    setFilteredTags([]);
  };

  // Remove a tag from localTags
  const handleRemoveTag = (index) => {
    setLocalTags((prev) => prev.filter((_, i) => i !== index));
  };

  // Handle pressing Enter to add a new tag
  const handleAddTag = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      setLocalTags((prev) => [...prev, tagInput.trim()]);
      setTagInput("");
    }
  };

  // If user backspaces in an empty text input, revive the last tag
  const handleTagBackspace = (e) => {
    if (e.key === "Backspace" && tagInput === "" && localTags.length > 0) {
      setTagInput(localTags[localTags.length - 1]);
      setLocalTags((prev) => prev.slice(0, -1));
    }
  };

  // When input field loses focus, add the typed tag if any
  const handleAddTagOnBlur = () => {
    if (tagInput.trim() !== "") {
      setLocalTags((prev) => [...prev, tagInput.trim()]);
      setTagInput("");
      setFilteredTags([]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFile = {
      ...file,
      name,
      description,
      tags: localTags,
      aiUsable,
    };
    onSave(updatedFile);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-[90vw] max-w-[500px] shadow-lg">
        <h2 className="text-xl font-bold mb-4">Edit File</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Name */}
          <div>
            <label htmlFor="name" className="block mb-1 font-semibold">
              Name:
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm"
            />
          </div>

          {/* Description */}
          <div>
            <label htmlFor="description" className="block mb-1 font-semibold">
              Description:
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 h-24 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 shadow-sm resize-none"
            />
          </div>

          {/* Tags */}
          <div className="relative">
            <label className="block mb-1 font-semibold">Tags:</label>
            <div className="flex items-center flex-wrap border border-gray-300 rounded-lg p-2 focus-within:ring focus-within:ring-blue-500">
              {localTags.map((tag, index) => (
                <div
                  key={index}
                  className="flex items-center bg-blue-600 text-white text-sm rounded-full px-3 py-1 mr-2 mb-2 shadow"
                >
                  <span>{tag}</span>
                  <button
                    type="button"
                    className="ml-2 text-white font-bold hover:text-gray-300"
                    onClick={() => handleRemoveTag(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Type to search or add tags"
                  className="w-full focus:outline-none text-gray-700 bg-transparent"
                  value={tagInput}
                  onChange={(e) => {
                    setTagInput(e.target.value);
                    handleFilterTags(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    handleAddTag(e);
                    handleTagBackspace(e);
                  }}
                  onBlur={handleAddTagOnBlur}
                />
                {filteredTags.length > 0 && (
                  <ul className="absolute z-10 bg-white border border-gray-300 rounded-md w-full mt-1 max-h-40 overflow-auto shadow-lg">
                    {filteredTags.map((tag, index) => (
                      <li
                        key={index}
                        className="px-3 py-2 cursor-pointer hover:bg-gray-100 text-sm text-gray-700"
                        onMouseDown={() => handleSelectTag(tag)}
                      >
                        {tag}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>

          {/* AI Usable */}
          <div className="flex items-center">
            <label htmlFor="aiUsable" className="mr-2 font-semibold">
              Usable by AI:
            </label>
            <input
              id="aiUsable"
              type="checkbox"
              checked={aiUsable}
              onChange={(e) => setAiUsable(e.target.checked)}
              disabled={!isEmbedded}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-end space-x-3">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow"
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded shadow"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditFilePopup;
