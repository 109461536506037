// Import necessary modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../components/head";
import { changeRole } from "../redux/userSlice";
import API from "../utils/api";

const fontSizeConfig = {
  teamName: "2.4rem",
  summary: "2rem",
  agentBoxHeight: "4rem",
  agentName: "3.2rem",
  agentStatus: "1rem",
};

const renderRoleSelection = (userRoles, userRole, handleChangeRole) => {
  if (!userRoles || !userRoles.includes("admin")) {
    return null;
  }

  return (
    <>
      <div>
        <AppHeader />
        {/* change role */}
        <label className="mr-4">Role:</label>
        <select
          value={userRole}
          onChange={(e) => handleChangeRole(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        >
          {userRoles.map((role) => {
            return (
              <option key={role} value={role}>
                {role}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

// Main Dashboard Component
function DashboardPage() {
  const dispatch = useDispatch();
  const useUserRoles = () => {
    let roles = useSelector((state) => state.user.userRoles);
    if (typeof roles === "string") {
      return roles.split(",");
    } else if (Array.isArray(roles)) {
      return roles;
    }
  };
  const userRoles = useUserRoles();
  const userRole = useSelector((state) => state.user.userRole);
  const handleChangeRole = (role) => {
    dispatch(changeRole(role));
  };
  const [format, setFormat] = useState(null);
  const [data, setData] = useState(null);

  const fetchData = () => {
    API.get("/dashboard/data")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  // Fetch format data on mount
  useEffect(() => {
    API.get("/dashboard/format")
      .then((response) => setFormat(response.data))
      .catch((error) => console.error("Error fetching format:", error));
  }, []);

  // Fetch data on mount and set column count based on response
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds
    return () => clearInterval(intervalId); // Clear interval
  }, []);

  if (!format | !data) {
    if (userRoles && userRoles.includes("admin")) {
      return (
        <>
          <div>
            <AppHeader />
          </div>
          <div className="flex justify-center items-center h-screen text-lg">
            Loading...
          </div>
        </>
      );
    } else {
      return (
        <div className="flex justify-center items-center h-screen text-lg">
          Loading...
        </div>
      );
    }
  } else {
    return (
      <>
        {renderRoleSelection(userRoles, userRole, handleChangeRole)}
        <div
          className="flex flex-col"
          style={{ height: "100%", width: "100%" }}
        >
          <div className={`grid grid-cols-1 sm:grid-cols-2`}>
            {Object.keys(data).map((team) => (
              <TeamColumn
                key={data[team].id}
                teamData={data[team]}
                format={format}
                role={userRole}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
}

// Team Column Component
function TeamColumn({ teamData, format, role }) {
  return (
    <div className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden">
      <h1
        className="font-semibold text-gray-800 p-4 bg-gray-50 border-b border-gray-200"
        style={{ fontSize: fontSizeConfig.teamName }}
      >
        {teamData.name}
      </h1>
      <TeamSummary summary={teamData.summary} format={format.public.summary} />
      {role !== "admin" ? (
        <AgentList agents={teamData.agents} format={format} />
      ) : (
        <AgentListAdmin agents={teamData.agents} format={format} role={role} />
      )}
    </div>
  );
}

// Team Summary Component
function TeamSummary({ summary, format }) {
  const fields = format.fields;
  let strings = fields.map((field) => {
    let value = summary[field.key];
    if (value === undefined) {
      return null;
    }
    if (field.name.includes("time")) {
      value = secondsToString(value);
    }
    const string = `${field.abbr} ${value}`;
    return string;
  });
  if (!strings) {
    return null;
  } else {
    // remove null values
    strings = strings.filter((string) => string !== null);
    const summaryString = strings.join(" | ");
    return (
      <div
        className=" bg-gray-50 border-b border-gray-200"
        style={{ fontSize: fontSizeConfig.summary }}
      >
        {summaryString}
      </div>
    );
  }
}

function sortByStatus(agents, statues) {
  let sortedAgents = [];
  statues.forEach((status) => {
    agents.forEach((agent) => {
      if (agent.call_status === status.key) {
        agent.color = status.color;
        agent.status = status.name;
        sortedAgents.push(agent);
      }
    });
  });
  return sortedAgents;
}

// Agent List Component
function AgentList({ agents, format }) {
  const sortedAgents = sortByStatus(agents, format.config.statues);

  if (!sortedAgents) {
    return null;
  } else {
    return (
      <div className="p-4">
        <div className="flex flex-col space-y-1">
          {sortedAgents.map((agent, index) => (
            <div
              key={index}
              className="flex items-center justify-center"
              style={{
                backgroundColor: agent.color,
                height: fontSizeConfig.agentBoxHeight,
                lineHeight: 1,
              }}
            >
              <div className="flex flex-row items-center justify-center space-x-1">
                <div style={{ fontSize: fontSizeConfig.agentName }}>
                  {agent.name.split(" ")[0]}
                </div>
                <div style={{ fontSize: fontSizeConfig.agentStatus }}>
                  ({agent.status})
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

function secondsToString(seconds) {
  let hours = (seconds / 3600).toFixed(1);
  if (hours > 1) {
    return `${hours}h`;
  } else {
    let minutes = (seconds / 60).toFixed(1);
    if (minutes > 1) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  }
}

function getAdminStatistic(agents, fields) {
  agents.forEach((agent) => {
    let call_statics = [];
    let time_statics = [];
    fields.forEach((field) => {
      let value = agent[field.key];
      if (field.name.includes("time")) {
        value = secondsToString(value);
        time_statics.push(`${field.abbr} ${value}`);
      } else {
        call_statics.push(`${field.abbr} ${value}`);
      }
    });
    agent.call_statics = call_statics.join(" | ");
    agent.time_statics = time_statics.join(" | ");
  });
  return agents;
}

// Agent List Component for Admin
function AgentListAdmin({ agents, format, role }) {
  let sortedAgents = sortByStatus(agents, format.config.statues, role);
  sortedAgents = getAdminStatistic(sortedAgents, format.admin.agents.fields);
  if (!sortedAgents) {
    return null;
  } else {
    return (
      <div className="p-4">
        <div className="flex flex-col space-y-1">
          {sortedAgents.map((agent, index) => (
            <div
              key={index}
              className="flex items-center justify-between"
              style={{
                backgroundColor: agent.color,
                height: fontSizeConfig.agentBoxHeight,
                lineHeight: 1,
              }}
            >
              <div className="flex flex-row items-center space-x-1">
                <div style={{ fontSize: fontSizeConfig.agentName }}>
                  {agent.name.split(" ")[0]}
                </div>
                <div style={{ fontSize: fontSizeConfig.agentStatus }}></div>
                <div className="flex flex-col">
                  <div>
                    ({agent.status}) {agent.call_statics}
                  </div>
                  <div>{agent.time_statics}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default DashboardPage;
