import React from "react";

function AgentsList({ agents, STATUSES, changeAgent: onChangeAgent }) {
  return (
    <div className="flex items-center justify-center">
      <ul className="list-none p-0">
        <li className="mb-2">
          <div className="grid grid-cols-5 gap-2 items-center font-bold">
            <div>Name</div>
            <div>Status</div>
            <div>Max Tickets</div>
            <div>Agent Workload</div>
            <div>Agent Weight Factor</div>
          </div>
        </li>
        {agents.map((agent) => {
          const displayStatus =
            STATUSES.find((status) => status === agent.status) || STATUSES[0];
          return (
            <li key={agent.id} className="mb-2">
              <div className="grid grid-cols-5 gap-2 items-center ">
                <div>{agent.name}</div>
                <div>
                  <select
                    value={displayStatus}
                    onChange={(e) =>
                      onChangeAgent(agent.id, "status", e.target.value)
                    }
                    className="w-full"
                  >
                    {STATUSES.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <input
                    type="number"
                    step="1"
                    className="border-2 border-gray-500 rounded-md"
                    value={agent.max_tickets}
                    onChange={(e) =>
                      onChangeAgent(agent.id, "max_tickets", e.target.value)
                    }
                  />
                </div>
                <div>
                  <input
                    type="number"
                    disabled
                    className="border-2 border-gray-500 rounded-md"
                    value={agent.workload}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    step="0.01"
                    className="border-2 border-gray-500 rounded-md"
                    value={agent.weight_factor}
                    onChange={(e) =>
                      onChangeAgent(agent.id, "weight_factor", e.target.value)
                    }
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default AgentsList;
