import {
  faArrowDownWideShort,
  faArrowUpWideShort,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../utils/api";
import Notification from "./notification";
const GroupAgentsEditPopup = ({ group, onClose }) => {
  const [agents, setAgents] = useState(null);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  let group_id;
  if (!group) {
    group_id = null;
  } else {
    group_id = group.group_id;
  }
  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };

  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const debouncedFetchAgents = useCallback(
    debounce(() => {
      API.get(`/team/groups/${group.id}/agents/`)
        .then((response) => {
          // const results = preconditionAgents(response.data);
          setAgents(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate("/login?next=/team/groups/");
          }
          console.log("Error: ", error);
        });
    }, 2000),
    [setAgents],
  );

  const debouncedHandleAgentChange = useCallback(
    debounce((group_pk, agent_pk, agent, resolve, reject) => {
      API.put(`/team/groups/${group_pk}/agents/${agent_pk}/`, agent, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("Response: ", response);
          resolve(response);
        })
        .catch((error) => {
          console.log("Error: ", error);
          reject(error);
        });
    }, 500),
    [],
  ); // Dependencies are set as an empty array if nothing affects the debounced function

  const handlesChangeAgent = (agent_pk, group_id, field, value) => {
    const group_pk = group.id;
    let agent = agents.find((agent) => agent.id === agent_pk);
    if (agent && field in agent["groups"][group_id]) {
      agent["groups"][group_id][field] = value;
      setAgents(
        agents.map((g) =>
          g.id === agent_pk
            ? {
                ...g,
                groups: {
                  ...g.groups,
                  [group_id]: { ...g.groups[group_id], [field]: value },
                },
              }
            : g,
        ),
      );
      debouncedHandleAgentChange(
        group_pk,
        agent_pk,
        agent,
        () => {
          setNotification({
            title: "Success",
            message: "Agent updated successfully!",
            color: "green",
            timeToDisappear: 3000,
          });
        },
        (error) => {
          const data = error.response.data;
          const obj = data["groups"][field];
          const message = obj[field];
          const original = obj["original"];
          const old_value = original[field];

          setAgents(
            agents.map((g) =>
              g.id === agent_pk
                ? {
                    ...g,
                    groups: {
                      ...g.groups,
                      [group_id]: { ...g.groups[group_id], [field]: old_value },
                    },
                  }
                : g,
            ),
          );

          setNotification({
            title: "Error",
            message: message,
            color: "red",
            timeToDisappear: 5000,
          });
        },
      );
    }
  };

  useEffect(() => {
    debouncedFetchAgents();
  }, [debouncedFetchAgents]);

  // Add sorting functions
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        direction = "descending";
      } else if (sortConfig.direction === "descending") {
        direction = null;
        setSortConfig({ key: null, direction: null });
        return;
      } else {
        direction = "ascending";
      }
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        return <FontAwesomeIcon icon={faArrowUpWideShort} />;
      } else if (sortConfig.direction === "descending") {
        return <FontAwesomeIcon icon={faArrowDownWideShort} />;
      }
    }
    return null;
  };

  // Add sorted agents computation
  const sortedAgents = React.useMemo(() => {
    if (!agents || !sortConfig.key || !sortConfig.direction) {
      return agents;
    }

    return [...agents].sort((a, b) => {
      let aValue =
        sortConfig.key === "name"
          ? a[sortConfig.key]
          : a.groups[group_id][sortConfig.key];
      let bValue =
        sortConfig.key === "name"
          ? b[sortConfig.key]
          : b.groups[group_id][sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [agents, sortConfig, group_id]);

  if (!group) {
    return;
  } else {
    return (
      <div className="popup">
        <div className="popup-content">
          {/* background gray 500 */}
          <div className="flex justify-between items-center mb-4 bg-gray-200 p-2">
            <h2>Edit Group: {group.name}</h2>
            {/* close icon of awesome */}
            <FontAwesomeIcon
              icon={faXmark}
              onClick={onClose}
              className="cursor-pointer"
            />
          </div>
          {agents && agents.length > 0 && (
            <table className="min-w-full table-auto border-collapse border border-gray-200 shadow-lg">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() => requestSort("name")}
                  >
                    Name {getArrow("name")}
                  </th>
                  <th
                    className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() => requestSort("group_weight")}
                  >
                    Group weight factor {getArrow("group_weight")}
                  </th>
                  <th
                    className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() => requestSort("group_workload")}
                  >
                    Group workload {getArrow("group_workload")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedAgents.map((agent, index) => (
                  <tr
                    key={agent.id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                      {agent.name}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                      <input
                        type="number"
                        step="0.01"
                        className="border-2 border-gray-500 rounded-md disabled:opacity-50"
                        value={agent["groups"][group_id]["group_weight"]}
                        onChange={(e) =>
                          handlesChangeAgent(
                            agent.id,
                            group_id,
                            "group_weight",
                            e.target.value,
                          )
                        }
                      />
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                      {agent["groups"][group_id]["group_workload"]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {notification.message && (
          <Notification
            title={notification.title}
            message={notification.message}
            color={notification.color}
            timeToDisappear={notification.timeToDisappear}
            onDisappear={handleResetNotification}
          />
        )}
      </div>
    );
  }
};

export default GroupAgentsEditPopup;
