import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../utils/api";

/**
 * @module useWarehouseAccessory
 * @description Custom React hook for fetching, updating, and deleting a warehouse accessory, and creating/downloading shipping labels.
 *
 * References:
 *  - AccessoryDetailView (GET, PUT, DELETE) at /warehouse/accessories/<str:accessory_id>/
 *  - AccessoryShippingLabelView at /warehouse/accessories/<str:accessory_id>/shipping_label/
 *
 * @typedef {object} UpdateAccessoryPayload
 * @property {string} [name] - Updated name of the accessory
 * @property {string} [description] - Updated description
 * @property {string} [shipping_status] - Updated shipping status
 *
 * @typedef {object} ShippingLabelResponse
 * @property {string} accessory_id - ID of the accessory
 * @property {boolean} shipping_label_created - Indicates if a shipping label was created
 * @property {string} shipping_status - Shipping status of the accessory
 * @property {string} shipping_label_url - URL of the shipping label
 *
 * @function useWarehouseAccessory
 * @description Hook that provides methods to fetch, update, delete accessories, and create/download shipping labels via Axios-based API calls.
 * @returns {{
 *   error: string | null,
 *   loading: boolean,
 *   updatedAccessory: object | null,
 *   deletedAccessoryId: string | null,
 *   shippingLabelData: ShippingLabelResponse | null,
 *   updateAccessory: (accessoryId: string, updatedFields: UpdateAccessoryPayload) => Promise<void>,
 *   deleteAccessory: (accessoryId: string) => Promise<void>,
 *   createShippingLabel: (accessoryId: string) => Promise<void>,
 *   downloadShippingLabel: (accessoryId: string) => Promise<void>,
 *   getAccessory: (accessoryId: string) => Promise<any | null>
 * }}
 * Returns an object containing:
 * - error: Any error message from the last operation
 * - loading: Indicates operation in progress
 * - updatedAccessory: The updated accessory after a successful update
 * - deletedAccessoryId: The ID of the accessory successfully deleted
 * - shippingLabelData: Result of shipping label creation
 * - updateAccessory: Function to update an accessory
 * - deleteAccessory: Function to delete an accessory
 * - createShippingLabel: Function to create a new shipping label for an accessory
 * - downloadShippingLabel: Function to download or view an existing shipping label for an accessory (GET method)
 * - getAccessory: Function to fetch an accessory by ID
 */
export function useWarehouseAccessory() {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatedAccessory, setUpdatedAccessory] = useState(null);
  const [deletedAccessoryId, setDeletedAccessoryId] = useState(null);
  const [shippingLabelData, setShippingLabelData] = useState(null);

  /**
   * @async
   * @function getAccessory
   * @description Fetches a single accessory by sending a GET request.
   * @param {string} accessoryId - The unique ID of the accessory to fetch
   * @returns {Promise<any|null>} The accessory data or null on failure
   */
  const getAccessory = useCallback(
    async (accessoryId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.get(
          `/warehouse/accessories/${accessoryId}/`,
        );
        return response.data;
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/accessories");
        } else {
          throw err;
        }
        return null;
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  /**
   * @async
   * @function updateAccessory
   * @description Updates an accessory by sending a PUT request.
   * @param {string} accessoryId - The unique ID of the accessory to update
   * @param {UpdateAccessoryPayload} updatedFields - Fields to update in the accessory
   * @returns {Promise<void>}
   */
  const updateAccessory = useCallback(
    async (accessoryId, updatedFields) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.put(
          `/warehouse/accessories/${accessoryId}/`,
          updatedFields,
        );
        setUpdatedAccessory(response.data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/accessories");
        } else {
          setError(
            err?.response?.data?.error ||
              err.message ||
              "Failed to update the accessory",
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  /**
   * @async
   * @function deleteAccessory
   * @description Deletes an accessory by sending a DELETE request.
   * @param {string} accessoryId - The unique ID of the accessory to delete
   * @returns {Promise<void>}
   */
  const deleteAccessory = useCallback(
    async (accessoryId) => {
      setLoading(true);
      setError(null);
      try {
        await API.delete(`/warehouse/accessories/${accessoryId}/`);
        setDeletedAccessoryId(accessoryId);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/accessories");
        } else {
          setError(
            err?.response?.data?.error ||
              err.message ||
              "Failed to delete the accessory",
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  /**
   * @async
   * @function createShippingLabel
   * @description Creates or updates a shipping label for an accessory by sending a POST request.
   * @param {string} accessoryId - The unique ID of the accessory to create a shipping label for
   * @returns {Promise<void>}
   */
  const createShippingLabel = useCallback(
    async (accessoryId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.post(
          `/warehouse/accessories/${accessoryId}/shipping_label/`,
        );
        setShippingLabelData(response.data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/accessories");
        } else {
          setError(
            err?.response?.data?.error ||
              err.message ||
              "Failed to create shipping label",
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  /**
   * @async
   * @function downloadShippingLabel
   * @description Retrieves the PDF from a GET request (FileResponse with content_type="application/pdf") and opens it inline so the user can view it in a new tab.
   * @param {string} accessoryId - The unique ID of the accessory to download or view the shipping label for
   * @returns {Promise<void>}
   */
  const downloadShippingLabel = useCallback(
    async (accessoryId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.get(
          `/warehouse/accessories/${accessoryId}/shipping_label/`,
          { responseType: "blob" },
        );

        const contentDisposition =
          response.headers["content-disposition"] || "";
        let filename = "shipping_label.pdf";
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        if (match && match[1]) {
          filename = match[1];
        }

        const blobUrl = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobUrl);
        }, 60000);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/login?next=/warehouse/accessories");
        } else {
          setError(
            err?.response?.data?.error ||
              err.message ||
              "Failed to download (view) shipping label",
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  return {
    error,
    loading,
    updatedAccessory,
    deletedAccessoryId,
    shippingLabelData,
    updateAccessory,
    deleteAccessory,
    createShippingLabel,
    downloadShippingLabel,
    getAccessory,
  };
}
