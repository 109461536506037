import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GroupsList from "../../components/GroupsList";
import Notification from "../../components/notification";
import API from "../../utils/api";
function GroupEditPage() {
  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const [groups, setGroups] = useState(null);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "",
    timeToDisappear: 3000,
  });

  const handleResetNotification = () => {
    setNotification({
      title: "",
      message: "",
      color: "",
      timeToDisappear: 3000,
    });
  };

  const debouncedFetchGroups = useCallback(
    debounce(() => {
      API.get("/team/groups/")
        .then((response) => {
          setGroups(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate("/login?next=/team/groups/");
          }
          console.log("Error: ", error);
        });
    }, 2000),
    [setGroups],
  );

  // Define the debounced function inside useCallback
  const debouncedHandleGroupChange = useCallback(
    debounce((group_id, group, resolve, reject) => {
      API.put(`/team/groups/${group_id}/`, group, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("Response: ", response);
          resolve(response);
        })
        .catch((error) => {
          console.log("Error: ", error);
          reject(error);
        });
    }, 2000),
    [],
  ); // Dependencies are set as an empty array if nothing affects the debounced function

  const onChangeGroup = (group_id, field, value) => {
    let group = groups.find((group) => group.id === group_id);
    if (group && field in group) {
      group[field] = value;
      setGroups(
        groups.map((g) => (g.id === group_id ? { ...g, [field]: value } : g)),
      );
      debouncedHandleGroupChange(
        group_id,
        group,
        (response) => {
          setNotification({
            title: "Success",
            message: "Group updated successfully!",
            color: "green",
            timeToDisappear: 3000,
          });
        },
        (error) => {
          const data = error.response.data;
          const message = data[field][field];
          const original = data[field]["original"];
          const old_value = original[field];

          setGroups(
            groups.map((g) =>
              g.id === original.id ? { ...g, [field]: old_value } : g,
            ),
          );

          setNotification({
            title: "Error",
            message: message,
            color: "red",
            timeToDisappear: 5000,
          });
        },
      );
    }
  };

  useEffect(() => {
    debouncedFetchGroups();
  }, [debouncedFetchGroups]);

  if (!groups) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <GroupsList groups={groups} changeGroup={onChangeGroup} />
        {notification.message && (
          <Notification
            title={notification.title}
            message={notification.message}
            color={notification.color}
            timeToDisappear={notification.timeToDisappear}
            onDisappear={handleResetNotification}
          />
        )}
      </div>
    );
  }
}

export default GroupEditPage;
